import React, { useState, useEffect, useMemo, useRef } from "react";
// import { Link, useHistory } from "react-router-dom";
// import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../firebase";
import { toast } from "react-toastify";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import { TableHeader, Pagination, Search } from "../Table/DataTable";
import AdminBackNav from "./AdminBackNav";
import { Modal, Button } from 'react-bootstrap';
import { MdDelete, Md1KPlus } from "react-icons/md";
import { ImPlus } from 'react-icons/im';
import { HiLockClosed, HiPlusCircle ,HiOutlineEye } from "react-icons/hi";
import { GrClose } from "react-icons/gr";



function Rooms() {

    // let history = useHistory();
    const ref = useRef();
    const [RoomDetails, setRoomDetails] = useState({});
    const [deleteCategory, setdeleteCategory] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(1);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [comments, setComments] = useState([]);
    const [file, setFile] = useState(null);
    const [photoIndex, setphotoIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const imageviews = ((e) => {
        setisOpen(true)
        setphotoIndex(e)

    })


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setdeleteCategory(e)
        setShow(true)
    };


    const handleImage = (event) => {

        setFile(event.target.files[0])
    };
    async function sendMessagesss(e) {
        e.preventDefault();

        if (file !== null) {
            const ref = storage.ref(`/images/gallery/${file.name}`);
            const uploadTask = ref.put(file);
            const oderDone = uploadTask.on(
                "state_changed",
                console.log,
                console.error,
                () => {
                    ref.getDownloadURL().then((url) => {
                        setFile(null);


                        const abc = db.collection("roomDetails").add({
                            image: url,
                            gueste: RoomDetails?.gueste,
                            area: RoomDetails?.area,
                            roomtype: RoomDetails?.roomtype,
                            aboutRoom: RoomDetails?.aboutRoom,
                            Price: RoomDetails?.price,
                            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                        });

                        toast.success("successfully Add")
                        reset()
                    });
                }
            );

        } else {
        }
    }

    const reset = () => {
        ref.current.value = "";
    };

    useEffect(() => {
      const unsub =  db.collection('roomDetails').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setComments(oderdetails)
        })

        return () =>{
            unsub()
        }
    }, [])

    const hendledelete = (async (e) => {
        setShow(false)
        const res = await db.collection('roomDetails').doc(deleteCategory).delete();
        toast.success("deleted ")

    })


    const hendleitemsPerPage = (e) => {
        setitemsPerPage(parseInt(e.target.value));
    };

    const hendlesetRoomDetailsAdd = (e) => {
        const { name, value } = e.target;
        setRoomDetails({ ...RoomDetails, [name]: value });
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const headers = [
        // { name: "No", field: "id", sortable: true },
        { name: "Image", field: "image", sortable: false },
        // { name: "Number", field: "number", sortable: false },
        { name: "Gueste", field: "gueste", sortable: false },
        { name: "area", field: "area", sortable: false },
        { name: "roomtype", field: "roomtype", sortable: false },
        { name: "aboutRoom", field: "aboutRoom", sortable: false },
        { name: "Price", field: "Price", sortable: false },
        { name: "Action", field: "action", sortable: false },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.email.toLowerCase().includes(search.toLowerCase())
            );
        }

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * itemsPerPage,
            (currentPage - 1) * itemsPerPage + itemsPerPage
        );
    }, [comments, currentPage, search, sorting, itemsPerPage]);

    const handlePageClick = (event) => {
        const newOffset = (event.selected) % (comments.length / 2);
        setCurrentPage(newOffset + 1)
        setItemOffset(newOffset * itemsPerPage);

    };



    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(comments.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, comments]);


    const editUser = (val, rowData) => {
        if (val === 'add') {
            document.getElementById('editDiv').style.display = 'block';
            document.getElementById('roomsShow').style.display = 'none';
        }
        if (val === 'cencel') {
            document.getElementById('editDiv').style.display = 'none';
            document.getElementById('roomsShow').style.display = 'block';
        }
    }


    const [roomDetailsAbout, setRoomDetailsAbout] = useState({})
    const [roomId, setroomId] = useState()

    const roomDetailsView = (val, rowData) => {

        // console.log(rowData);
        if (val === 'show') {

            console.log("snapshot");
            db.collection('roomDetails').doc(rowData).get().then(snapshot => {


                setRoomDetailsAbout(snapshot?.data());
            });



            document.getElementById('roomView').style.display = 'block';
            document.getElementById('roomsShow').style.display = 'none';


        }
        if (val === 'cencel') {
            document.getElementById('roomView').style.display = 'none';
            document.getElementById('roomsShow').style.display = 'block';
        }
    }



    return (
        <div>
            <AdminBackNav />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item active">
                                <a>Room Details</a>
                            </li>
                        </ol>
                    </div>

                    <div className="row" id="roomsShow">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header roomT">
                                    <h4 className="card-title">Room Details</h4>

                                    <div className="addPlus">
                                        <a
                                            // href="#category"
                                            className="login_button newQue"
                                            // data-toggle="modal"
                                            onClick={(e) => { editUser("add") }}
                                        >
                                            {/* Add */}

                                            <HiPlusCircle />
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div
                                        id="example3_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <div
                                            className="dataTables_length category"
                                            id="example3_length"
                                        >
                                            <label>
                                                Show{" "}
                                                <div className="dropdown category">
                                                    <select
                                                        name="example3_length"
                                                        aria-controls="example3"
                                                        className=""
                                                        tabIndex="-98"
                                                        onChange={(e) => {
                                                            hendleitemsPerPage(e);
                                                        }}
                                                    >
                                                        <option value={"10"}>10</option>
                                                        <option value={"25"}>25</option>
                                                        <option value={"50"}>50</option>
                                                        <option value={"100"}>100</option>
                                                    </select>
                                                </div>{" "}
                                                entries
                                            </label>
                                        </div>
                                        {/* <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div> */}

                                        <div className="row w-100 tableResponsiv">
                                            <div className="col mb-3 col-12 text-center">
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 d-flex flex-row-reverse">

                                                    </div>
                                                </div>

                                                <table className="table table-striped">
                                                    <TableHeader
                                                        headers={headers}
                                                        onSorting={(field, order) =>
                                                            setSorting({ field, order })
                                                        }
                                                    />
                                                    <tbody>
                                                        {commentsData.map((comment, i) => {
                                                            const createDate = "SDfdsf" /*  dateFormat(
                                                                comment?.createdAt?.toDate(),
                                                                "dd-mm-yyyy"
                                                            ); */







                                                            return (
                                                                <tr key={i}>

                                                                    <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td>

                                                                    <td>{comment?.gueste}</td>
                                                                    <td>{comment?.area}</td>
                                                                    <td>{comment?.roomtype}</td>
                                                                    <td>{comment?.aboutRoom?.length > 16? comment?.aboutRoom.substring(0, 15)+"." : comment?.aboutRoom }</td>

                                                                    



                                                                    <td>{comment?.Price}</td>
                                                                    <td>
                                                                        <div className="d-flex">


                                                                            <a
                                                                                /* href="#views" data-toggle="modal" */
                                                                                className="btn btn-primary shadow btn-xs sharp mr-1" onClick={(e) => { roomDetailsView("show", comment?.id) }}
                                                                            >
                                                                             <HiOutlineEye/> <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a>


                                                                            <a
                                                                                href="#delete" data-toggle="modal"
                                                                                className="btn btn-danger shadow btn-xs sharp" onClick={(e) => { handleShow(comment?.id) }}
                                                                            >
                                                                                <MdDelete />  <i className="fa fa-trash"></i>
                                                                            </a>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="dataTables_info category"
                                            id="example3_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing {itemOffset} to {comments?.length < itemOffset + itemsPerPage ? comments?.length : itemOffset + itemsPerPage} of{" "}
                                            {/* {getParentCategory && getParentCategory?.length} */}{" "}
                                            {comments?.length} entries
                                        </div>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            className="dataTables_paginate paging_simple_numbers category"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {isOpen && (
                <Lightbox
                    mainSrc={comments[photoIndex].image}
                    nextSrc={comments[(photoIndex + 1) % comments.length]}
                    prevSrc={comments[(photoIndex + comments.length - 1) % comments.length]}
                    onCloseRequest={() => setisOpen(false)}
                    onMovePrevRequest={() => setphotoIndex((photoIndex + comments.length - 1) % comments.length)
                    }
                    onMoveNextRequest={() => setphotoIndex((photoIndex + 1) % comments.length)
                    }
                />
            )}


            <div className="bs-example">
                <div id="category" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                                    Create Category
                                </h5>
                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: "26px" }}>
                                <div className="assignment_form loader-parent">
                                    <form>
                                        <input

                                            type="file"
                                            className="form-control input-default "
                                            name="image"

                                            ref={ref}
                                            accept="image/*"
                                            onChange={
                                                handleImage}
                                        />

                                        <div className="submitBtn">
                                            <button
                                                type="button"
                                                onClick={(e) => { sendMessagesss(e) }}
                                                className="btn btn-primary"
                                                data-dismiss="modal"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </form>

                                    <div
                                        className="loader-bx lodding "
                                        style={{ display: "none" }}
                                    >
                                        <img src="img/lodder.gif" alt="loader" className="center" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="bs-example">
                <div id="delete" className="modal fade" tabIndex="-1">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">

                                <button type="button" className="close" data-dismiss="modal">
                                    &times;
                                </button>
                            </div>
                            <div>
                                <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                                    Are you sure want to delete Image
                                </h5>
                                <div className="deleteBtn">

                                    <button type="button" className="btn btn-outline-danger" data-dismiss="modal" onClick={hendledelete} >Yes</button>
                                    <button type="button" className="btn btn-outline-success" data-dismiss="modal">No</button>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div id='editDiv' style={{ display: 'none', border: "0.4px solid white", borderRadius: "10px", boxShadow: "1px 1px 5px 1px #888888", margin: "30px" }}>

                <div className="roomadd">
                    <div className="roomaddBack">

                        <h3>Add Room Details</h3>
                        <div className="roomaddBackB" onClick={(e) => { editUser("cencel") }} ><GrClose /> </div>
                    </div>



                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <input

                                type="file"
                                className="form-control input-default "
                                name="image"
                                ref={ref}
                                accept="image/*"
                                onChange={
                                    handleImage}
                            />
                        </div>
                        <div className="col-12 col-sm-6">
                            <input name="gueste" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Gueste Count" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="area" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Area in sq" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="roomtype" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Room Type" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="aboutRoom" type="text" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter About Room" />
                        </div>
                        <div className="col-12 col-sm-6" >
                            <input name="price" type="number" onChange={(e) => { hendlesetRoomDetailsAdd(e) }} placeholder="Enter Price" />
                        </div>
                        <div className="col-12 col-sm-12">

                            <a className="roomAdd" onClick={(e) => { sendMessagesss(e) }}>Submit</a>
                        </div>
                    </div>

                </div>
            </div>


            <div id='roomView' style={{ display: 'none', border: "0.4px solid white", borderRadius: "10px", boxShadow: "1px 1px 5px 1px #888888", margin: "30px" }}>

                <div className="roomadd">
                    <div className="roomaddBack">






                        <h3> Room Details</h3>
                        <div className="roomaddBackB" onClick={(e) => { roomDetailsView("cencel") }} ><GrClose /> </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            Gueste : {roomDetailsAbout?.gueste}
                        </div>
                        <div className="col-12 col-md-6">
                            Area : {roomDetailsAbout?.area}
                        </div>
                        <div className="col-12 col-md-6">
                            Room Type : {roomDetailsAbout?.roomtype}
                        </div>
                        <div className="col-12 col-md-6">
                            Price : {roomDetailsAbout?.Price}
                        </div>
                        <div className="col-12">
                            <div className="roomDImg">
                                <img src={roomDetailsAbout?.image} alt="" />
                            </div>

                        </div>
                        <div className="col-12">
                            About Room : {roomDetailsAbout?.aboutRoom}
                        </div>

                    </div>

                    {/* <h1>Proceesing</h1> */}

                </div>
            </div>




            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <div>
                    <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                        Are you sure want to delete Room
                    </h5>

                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hendledelete}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>



        </div>
    )
}

export default Rooms