import React, { useEffect, useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Routes, Route} from 'react-router-dom'
// import { Login } from './pages/Login';
// import {Register}  from './pages/Register';
// import Win from './pages/Win';
// import MinePage from './pages/MinePage';
// import PrivacyPolicy from './pages/PrivacyPolicy';
// import Terms from './pages/Terms';
// import About from './pages/About';

// import ResetPassword  from './pages/ResetPassword';
// import AddAddress from './pages/AddAddress';
// import AddBankCard from './pages/AddBankCard';
// import Withdrawal from './pages/Withdrawal';
// import Orders from './pages/Orders';
// import ComplaintsSuggestions from './pages/ComplaintsSuggestions';
// import Promotion from './pages/Promotion';
// import Transactions from './pages/Transactions';
// import RiskDisclosureAgreement from './pages/RiskDisclosureAgreement';
// import Recharge from './pages/Recharge';
// import AuthUser from "./AuthUser";
import AdminPage from './pages/AdminPage';


// import UserManagement from './adminPages/UserManagement';
// import Pages from './adminPages/Pages';
// import WinTwo from './pages/WinTwo';

import Error404 from './pages/Error404';
// import Order from './adminPages/Order';
// import Period from './adminPages/Period';
// import GameResult from './adminPages/GameResult';
// import GameRules from './adminPages/GameRules';
// import Payments from './adminPages/Payment';
// import GameTC from './adminPages/GameTC';
// import Promotions from './adminPages/Promotions';
// import Wallet from './adminPages/Wallet'
// import Tickets from './adminPages/Tickets'
// import Gamesetting from './adminPages/Gamesetting'
// import Setting from './adminPages/Setting'

import LoginAdmin from './adminPages/Login'

import AuthAdmin from './AuthAdmin';
// import AdminLogin from './adminPages/AdminLogin'

import Rooms from './adminComponent/Rooms';
import Enquiry from './adminComponent/Enquiry';
import Reviews from './adminComponent/Reviews';
import ContectEnquiry from './adminComponent/ContectEnquiry';
import Gallery from './adminComponent/Gallery';



function App() {




  return (
    <>
      <Routes>
          <Route exact path='/' element={<LoginAdmin />}/>
          <Route path='*' element={<Error404 />} />
          <Route path='/admin/login' element={<AuthAdmin cmp={LoginAdmin} />}/>
          <Route path='/admin' element={<AuthAdmin cmp={AdminPage}/>}/>
       
          <Route path='/admin/rooms' element={<AuthAdmin cmp={Rooms} />}/>
          <Route path='/admin/enquiry' element={<AuthAdmin cmp={Enquiry} />}/>
          <Route path='/admin/reviews' element={<AuthAdmin cmp={Reviews} />}/>
          <Route path='/admin/contact-enquiry' element={<AuthAdmin cmp={ContectEnquiry} />}/>
          <Route path='/admin/gallery' element={<AuthAdmin cmp={Gallery} />}/>




       
      </Routes>
        
    </>
  );
}

export default App;
