import React, { useState, useEffect, useMemo, useRef } from "react";
// import { Link, useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import firebase from "firebase";
import { db, storage } from "../firebase";
import { toast } from "react-toastify";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { Modal, Button } from 'react-bootstrap';
import { TableHeader, Pagination, Search } from "../Table/DataTable";
import AdminBackNav from "./AdminBackNav";
import { MdDelete } from "react-icons/md";

function ContectEnquiry() {

    const [deleteCategory, setdeleteCategory] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setitemsPerPage] = useState(10);
    const [comments, setComments] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e) => {
        setdeleteCategory(e)
        setShow(true)
    };

    console.log(comments);



    useEffect(() => {
        const unSub = db.collection('contectEnquiry').orderBy('createdAt', 'desc').onSnapshot(snapshot => {
            const oderdetails = snapshot.docs.map((e) => {
                const data = e.data()
                data.id = e.id
                return data
            });
            setComments(oderdetails)
        })
        return () => {
            unSub()
        }


    }, [])
    const hendledelete = (async (e) => {
        setShow(false)
        const res = await db.collection('contectEnquiry').doc(deleteCategory).delete();
        toast.success("deleted ")

    })

    const hendleitemsPerPage = (e) => {
        setitemsPerPage(parseInt(e.target.value));
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const headers = [
        // { name: "No", field: "id", sortable: true },
        { name: "Name", field: "name", sortable: true },
        { name: "Number", field: "phoneNumber", sortable: false },
        { name: "Message", field: "message", sortable: true },
   
        { name: "Enquiry Date", field: "createDate", sortable: false },
        { name: "Action", field: "action", sortable: false },
    ];

    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.phoneNumber.toLowerCase().includes(search.toLowerCase())
            );
        }
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * itemsPerPage,
            (currentPage - 1) * itemsPerPage + itemsPerPage
        );
    }, [comments, currentPage, search, sorting, itemsPerPage]);



    const handlePageClick = (event) => {
        const newOffset = (event.selected) % (comments.length / 2);
        setCurrentPage(newOffset + 1)
        setItemOffset(newOffset * itemsPerPage);

    };

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageCount(Math.ceil(comments.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, comments]);

  return (
    <div>
         <AdminBackNav />
            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">

                            <li className="breadcrumb-item active">
                                <a>Enquiry</a>
                            </li>
                        </ol>
                    </div>

                    <div className="row" id="roomsShow">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Enquiry</h4>

                                    {/* <div className="addPlus">
                                <a
                                    className="login_button newQue"       
                                    onClick={(e) => { editUser("add") }}
                                >
                                    Add
                                    <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                </a>
                            </div> */}

                                </div>
                                <div className="card-body">
                                    <div
                                        id="example3_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <div
                                            className="dataTables_length category"
                                            id="example3_length"
                                        >
                                            <label>
                                                Show{" "}
                                                <div className="dropdown category">
                                                    <select
                                                        name="example3_length"
                                                        aria-controls="example3"
                                                        className=""
                                                        tabIndex="-98"
                                                        onChange={(e) => {
                                                            hendleitemsPerPage(e);
                                                        }}
                                                    >
                                                        <option value={"10"}>10</option>
                                                        <option value={"25"}>25</option>
                                                        <option value={"50"}>50</option>
                                                        <option value={"100"}>100</option>
                                                    </select>
                                                </div>{" "}
                                                entries
                                            </label>
                                        </div>
                                        <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div>

                                        <div className="row w-100 tableResponsiv">
                                            <div className="col mb-3 col-12 text-center">
                                                <div className="row">
                                                    <div className="col-md-6">

                                                    </div>
                                                    <div className="col-md-6 d-flex flex-row-reverse">

                                                    </div>
                                                </div>

                                                <table className="table table-striped">
                                                    <TableHeader
                                                        headers={headers}
                                                        onSorting={(field, order) =>
                                                            setSorting({ field, order })
                                                        }
                                                    />
                                                    <tbody>
                                                        {commentsData.map((comment, i) => {
                                                            const createDate = dateFormat(
                                                                comment?.createdAt?.toDate(),
                                                                "dd-mm-yyyy"
                                                            );
                                                            const checkInDate = dateFormat(
                                                                comment?.checkInDate?.toDate(),
                                                                "dd-mm-yyyy"
                                                            );
                                                            const checkOutDate = dateFormat(
                                                                comment?.checkOutDate?.toDate(),
                                                                "dd-mm-yyyy"
                                                            );





                                                            return (
                                                                <tr key={i}>

                                                                    {/* <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td> */}

                                                                    <td>{comment?.name}</td>
                                                                    <td>{comment?.phoneNumber}</td>
                                                                    <td>{comment?.message}</td>
                                                                    
                                                                    <td>{createDate}</td>
                                                                    <td>
                                                                        <div className="d-flex">


                                                                            {/* <a
                                                                        href="#views" data-toggle="modal" onClick={(e) => { viewHendle(comment?.id) }}
                                                                        className="btn btn-primary shadow btn-xs sharp mr-1"
                                                                    >
                                                                        <i className="fa fa-eye" aria-hidden="true"></i>
                                                                    </a> */}


                                                                            <a
                                                                                href="#delete" data-toggle="modal"
                                                                                className="btn btn-danger shadow btn-xs sharp" onClick={(e) => { handleShow(comment?.id) }}
                                                                            >
                                                                                <MdDelete />  <i className="fa fa-trash"></i>
                                                                            </a>

                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )

                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div
                                            className="dataTables_info category"
                                            id="example3_info"
                                            role="status"
                                            aria-live="polite"
                                        >
                                            Showing {itemOffset} to {comments?.length < itemOffset + itemsPerPage ? comments?.length : itemOffset + itemsPerPage} of{" "}
                                            {/* {getParentCategory && getParentCategory?.length} */}{" "}
                                            {comments?.length} entries
                                        </div>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            className="dataTables_paginate paging_simple_numbers category"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={2}
                                            pageCount={pageCount}
                                            previousLabel="< previous"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                </Modal.Header>
                <div>
                    <h5 className="modal-title" style={{ padding: "8px 27px" }}>
                        Are you sure want to delete Enquiry
                    </h5>

                </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={hendledelete}>
                        Yes
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        
    </div>
  )
}

export default ContectEnquiry