import firebase from 'firebase';
// import env from "react-dotenv";
  
const firebaseConfig = {
  apiKey: "AIzaSyBsRMJpMRiHJgqwLVQhCkOfqVlPDruoCYw",
  authDomain: "hotel-sd-prime.firebaseapp.com",
  projectId: "hotel-sd-prime",
  storageBucket: "hotel-sd-prime.appspot.com",
  messagingSenderId: "65894535975",
  appId: "1:65894535975:web:2a8bf1f5b602dc63a60f02",
  measurementId: "G-FT4XNMHPWE"
};
  
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
const db = firebase.firestore()
const storage = firebase.storage()
const devanalytics = firebase.analytics();
export {auth , firebase, db,storage, devanalytics};